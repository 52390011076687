
import { Component, Vue } from 'vue-property-decorator'
import { Page, Tag } from '../../types/common'
import { PageType } from '../../types/expert'
import { KeepAlive } from '@/utils/decorators'

@Component({
  name: 'ExpertList',
  filters: {
    isShowFilters (value: number) {
      return value === 0 ? '否' : value === 1 ? '是' : ''
    },
    tagListFilters (list: Tag[]) {
      return list.map((item: Tag) => {
        return item.tagName
      }).join('、')
    }
  }
})
@KeepAlive
export default class extends Vue {
  private searchInfo = {
    expertName: '',
    tagName: ''
  }

  private tableData: PageType[] = []
  private loading = false

  private typeList = []

  private total = 0
  private page = 1
  private size = 10

  created () {
    this.getData()
  }

  getData () {
    this.loading = true
    this.$axios.get<Page<PageType>>(this.$apis.expert.expertPage, {
      ...this.searchInfo,
      page: this.page,
      size: this.size
    })
      .then((res) => {
        this.tableData = res.list || []
        this.total = res.total || 0
      })
      .finally(() => {
        this.loading = false
      })
  }

  onSearch () {
    this.page = 1
    this.getData()
  }

  // 查看
  onDetail (id: string) {
    this.$router.push({
      name: 'expertDetail',
      params: { id }
    })
  }

  onAdd () {
    this.$router.push({
      name: 'expertAdd'
    })
  }

  onUpdate (id: string) {
    this.$router.push({
      name: 'expertUpdate',
      params: { id }
    })
  }

  onDelete (id: string) {
    this.$confirm('确认删除吗, 是否继续?', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    })
      .then(() => {
        this.$axios.post(this.$apis.expert.expertDelete, {
          expertId: id
        })
          .then(() => {
            this.$message({
              message: '删除成功',
              type: 'success'
            })
            this.page = 1
            this.getData()
          })
          .catch((err) => {
            this.$message.error(err)
          })
      })
      .catch((err) => {
        console.log(err)
      })
  }
}
